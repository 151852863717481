import { render, staticRenderFns } from "./dynamicinfo.vue?vue&type=template&id=3193b446&scoped=true"
import script from "./dynamicinfo.vue?vue&type=script&lang=js"
export * from "./dynamicinfo.vue?vue&type=script&lang=js"
import style0 from "./dynamicinfo.vue?vue&type=style&index=0&id=3193b446&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3193b446",
  null
  
)

export default component.exports