<template>
    <div class="dynamic">
        <img class="boximg" src="../assets/dynamic/1.png" alt="" srcset="">
        <div class="infoxbox">
            <div class="infotitle">{{obj.title}}</div>
            <div class="infodate"> {{obj.createdTime}} 发布</div>
            <div class="infohtml" v-html="obj.content"> </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            obj: {},
        };
    },
    created() {
        this.getGroupDynamicsDetail();
    },
    methods: {
        getGroupDynamicsDetail() {
            this.$api.dynamic
                .getGroupDynamicsDetail({
                    id: this.$route.query.id,
                })
                .then((res) => {
                    this.obj = res.data.result;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.dynamic {
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .infoxbox {
        width: 80vw;
        margin: 0 auto 30px;
        .infotitle {
            font-size: 30px;
            text-align: center;

            font-weight: bold;
        }
        .infodate {
            font-size: 14px;
            text-align: center;

            line-height: 30px;
            color: rgba(0, 0, 0, 0.59);
            margin: 20px 0;
        }
        .infohtml {
            max-width: 100%;
            // overflow: hidden;
            white-space: pre-wrap; /* css-3 */
            overflow: auto;
            word-break: break-all;
        }
    }
}
</style>